import { navigate } from "gatsby";
import { isFSA } from "../entity-resolver";
import { isBrowser } from "./is-browser";

const IB_PARAMS = {
  lid: "lid",
  pid: "pid",
  defaultLid: "80",
  defaultPid: "10162",
};

const getParamsFromUrl = () => {
  return new URLSearchParams(window.location.search);
};

export const getIBParamsAndSetToStorage = () => {
  if (isBrowser()) {
    // We should provide some specific IB param by default for LP COM website (https://oqtima-website.atlassian.net/jira/software/projects/OW/boards/1?selectedIssue=OW-389)
    const lid = getParamsFromUrl().get(IB_PARAMS.lid) || IB_PARAMS.defaultLid;
    const pid = getParamsFromUrl().get(IB_PARAMS.pid) || IB_PARAMS.defaultPid;

    if (pid && lid) {
      localStorage.setItem(IB_PARAMS.lid, lid);
      localStorage.setItem(IB_PARAMS.pid, pid);

      const { pathname } = window.location;
      navigate(pathname);
    }
  }
};

export const setIBparamsToLink = () => {
  // This parameter is currently disabled (wait for further updates)
  // if (isBrowser() && isFSA) {
  //   const pid = localStorage.getItem(IB_PARAMS.pid);
  //   const lid = localStorage.getItem(IB_PARAMS.lid);

  //   if (pid && lid) return `&${IB_PARAMS.lid}=${lid}&${IB_PARAMS.pid}=${pid}`;
  // }

  return "";
};
